import { LOGOUT } from '../api/Logout';
const prod = process.env.NODE_ENV === 'production';

// export const catchLoggedIn = async (history, client) => {
// 	const res = await client.query({ query: IS_LOGGED_IN }).then((res) => res.data.isLoggedIn).catch((err) => false);
// 	if (!!res) {
// 		history.push(routes.map);
// 	}
// };
export const logoutM = async (client) => {
	await client.mutate({ mutation: LOGOUT }).then((res) => {!!prod && console.log(res.data);	client.resetStore();})
		.catch((error) => {!!prod && console.log(error);client.resetStore();});
};

