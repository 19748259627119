const gql = require('graphql-tag');

export const LOGIN = gql`
	mutation login($Email: String!, $Password: String!) {
		login(data: { Email: $Email, Password: $Password }) {
			FirstName
			LastName
			Name
			Email
			Phone
		}
	}
`;
