import React, { useState } from 'react';
import { Modal } from '@material-ui/core';

const defaultState = {
	open: false,
	element: null,
	title: null
};

const ModalContext = React.createContext();

const ModalProvider = (props) => {
	const [ state, setValue ] = useState(defaultState);
	const openModal = (config) => setValue({ ...config, open: true });
	const closeModal = () => setValue({ ...state, title: null, element: null, open: false });

	return (
		<ModalContext.Provider
			value={{
				data: state,
				openModal,
				closeModal
			}}
			{...props}
		>
			{state.open && (
				<Modal onEscapeKeyDown={closeModal} title={state.title} open={state.open} onClose={closeModal}>
					{state.element}
				</Modal>
			)}
			{props.children}
		</ModalContext.Provider>
	);
};

function useModal() {
	const context = React.useContext(ModalContext);
	if (context === undefined) {
		console.log(`useModal must be used within a ModalContext`);
	}
	return context;
}

export { ModalProvider, useModal };
