export default {
	PROD: process.env.NODE_ENV === 'production',
	BACKEND_URL_DEV: process.env.REACT_APP_BACKEND_DEV,
	BACKEND_URL: process.env[`REACT_APP_${process.env.REACT_APP_TARGET}_OWNER_BACKEND`],
	GLOBAL_DATE_FORMAT: `YYYY-MM-DD HH:mm`,
	GLOBAL_TIME_FORMAT: `HH:mm`,
	POLL_INTERVAL: 1000 * 60 * 0.5,
	QUERY_ERROR_TEXT: `Brak połączenia`,
	QUERY_EMPTY_TEXT: `Brak danych`,
	PASSWORD: process.env[`REACT_APP_${process.env.REACT_APP_TARGET}_OWNER_PASSWORD`],
	GPS_PRECISION: 8,
	STATE_NAME: 'gtt-state',
	HERE_API: {
		CALCULATE_ROUTE_LIMIT: 128,
		ROUTE_TIME_INTERVAL: 10,
		APP_ID: process.env.REACT_APP_HERE_APP_ID,
		APP_CODE: process.env.REACT_APP_HERE_APP_CODE
	},
	MAP: {
		ICONS: {
			TRUCK_DIM: 50,
			TRUCK_COLOR: '#2677BD'
		}
	}
};
