import React from 'react';
export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null,
			info: null
		};
	}

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		this.setState(() => ({ error, info }));
		console.log(error);
		console.log(info);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className="error__boundry">
					<h1>{`404`}</h1>
					<h1>{`Strony nie odnaleziono`}</h1>
					{/* {this.state.error}
					{this.state.info} */}
				</div>
			);
		}

		return this.props.children;
	}
}
