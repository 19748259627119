import React, { Suspense, lazy } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Spinner from './components/Spinner';
import { useUser } from './store/user-context';
import { useAuth } from './store/auth-context';
const loadAuthenticatedApp = () => import('./App.Authenticated');
const AppAuthenticated = lazy(loadAuthenticatedApp);
const AppUnauthenticated = lazy(() => import('./App.Unauthenticated'));

const App = ({ isLoggedIn = false, user }) => {
	React.useEffect(() => {
		loadAuthenticatedApp();
	});
	return (
		<Suspense fallback={Spinner}>
			{!!user && user !== null && isLoggedIn ? (
				<BrowserRouter>
					<AppAuthenticated />
				</BrowserRouter>
			) : (
				<BrowserRouter>
					<AppUnauthenticated />
				</BrowserRouter>
			)}
		</Suspense>
	);
};

export default () => {
	const user = useUser();
	const { userIsLoggedIn, refetch } = useAuth();
	React.useEffect(() => {
		refetch();
	});

	return <App isLoggedIn={userIsLoggedIn} user={user} />;
};
