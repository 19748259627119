import React, { useState } from 'react';
import { useM } from '../utils/apollo-hooks';
import { ME } from '../api/Me';
// import { LOGOUT } from '../api/Logout';
import { LOGIN } from '../api/Login';
import { IS_LOGGED_IN } from '../api/IsLoggedIn';
import { auth } from '../clients';
import { Query } from 'react-apollo';
import config from '../utils/config';
import { logoutM } from '../routes';
const AuthContext = React.createContext();

const AuthProvider = (props) => {
	const [ userData, setUserData ] = useState({ user: null });
	const [ userIsLoggedIn, setUserIsLoggedIn ] = useState(null);
	const setUser = (userData) => setUserData({ user: { ...userData } });

	const getUser = () => async () => {
		await auth
			.query({ query: ME })
			.then((res) => {
				setUser(res.data.me);
				localStorage.setItem(config.STATE_NAME, JSON.stringify(res.data.me));
			})
			.catch((error) => {
				purgeUser();
			});
	};

	const login = (Email, Password) => async () => {
		const response = await useM(LOGIN, auth, { variables: { Email, Password } }, 'login')
			.then(() => setUser({ ...response }))
			.catch((err) => {
				setUser(err);
			});
	};

	const purgeUser = () => {
		//DELETE DATA FROM STORAGE
		localStorage.removeItem(config.STATE_NAME);
		//DELETE DATA FROM CONTEXT
		setUser(null);
		//SET USER LOGGED OUT
		setUserIsLoggedIn(false);
	};
	const logout = () => {
		logoutM(auth);
		purgeUser();
	};

	React.useEffect(() => {
		let storage = localStorage.getItem(config.STATE_NAME);
		if (storage) {
			setUser(JSON.parse(storage));
		} else if (!storage && userIsLoggedIn) {
			getUser();
		}
	}, []);

	React.useEffect(() => {
		if (userData && userData.user !== null) {
			localStorage.setItem(config.STATE_NAME, JSON.stringify(userData.user));
		}
	});

	return (
		<Query query={IS_LOGGED_IN} client={auth} fetchPolicy="no-cache">
			{({ data, error, loading, refetch }) => {
				if (!!error) {
					setUserIsLoggedIn(false);
				}
				if (!!data) {
					setUserIsLoggedIn(data.isLoggedIn);
				}
				return (
					<AuthContext.Provider
						value={{
							data: userData,
							setUser,
							getUser,
							logout,
							login,
							userIsLoggedIn,
							refetch
						}}
						{...props}
					/>
				);
			}}
		</Query>
	);
};

function useAuth() {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		!config.PROD && console.log(`useAuth must be used within a AuthProvider`);
	}
	return context;
}

export { AuthProvider, useAuth };
