export const useQ = async (query, client, propertyName, options = {}) =>
	await client
		.query({ query, options })
		.then((response) => {
			console.log(response.data);
			return Promise.resolve(response.data[`${propertyName}`]);
		})
		.catch((error) => {
			console.log(error);
			return Promise.resolve(error);
		});
export const useM = async (mutation, client, propertyName, options = {}) =>
	await client
		.mutate({ mutation, options })
		.then((response) => {
			console.log(response.data);
			return Promise.resolve(response.data[`${propertyName}`]);
		})
		.catch((error) => {
			console.log(error);
			return Promise.resolve(error);
		});
