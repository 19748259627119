//@ts-check
import React from 'react';
import './assets/styles/index.scss';
import * as serviceWorker from './serviceWorker';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { theme } from './utils/themeMui';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ErrorBoundry from './components/ErrorBoundry';
import { data } from './clients';
import App from './AuthLogic';
import AppProviders from './store';

const WrappedApp = (
	<ErrorBoundry>
		<MuiThemeProvider theme={theme}>
			<ApolloProvider client={data}>
				<ApolloHooksProvider client={data}>
					<AppProviders>
						<App />
					</AppProviders>
				</ApolloHooksProvider>
			</ApolloProvider>
		</MuiThemeProvider>
	</ErrorBoundry>
);

render(WrappedApp, document.getElementById('root'));

serviceWorker.unregister();
