import ApolloClient from 'apollo-boost';
import config from '../utils/config';


export const data = new ApolloClient({
	uri: config.PROD ? config.BACKEND_URL : config.BACKEND_URL_DEV,
	credentials: 'same-origin'
});

export const auth = new ApolloClient({
	uri: process.env.REACT_APP_AUTH, credentials: 'include'
});
