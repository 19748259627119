import React from 'react';
import { AuthProvider } from './auth-context';
import { UserProvider } from './user-context';
import { HereApiProvider } from './here-api-context';
import { ModalProvider } from './modal-context';

function AppProviders(props) {
	return (
		<AuthProvider>
			<UserProvider>
				<ModalProvider>{props.children}</ModalProvider>
			</UserProvider>
		</AuthProvider>
	);
}

export default AppProviders;

export const MapFunctionsProvider = ({ children }) => <HereApiProvider>{children}</HereApiProvider>;
