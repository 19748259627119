const gql = require('graphql-tag');

export const ME = gql`
	query {
		me {
			FirstName
			LastName
			Name
			Email
			Phone
		}
	}
`;
