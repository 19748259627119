import React, { useState, useContext } from 'react';

const HereApiContext = React.createContext();

const templateState = {
	routeId: 0,
	geolocation: [ {} ],
	routeDistance: 0,
	routeShape: [ { latitude: 0, longitude: 0, timestamp: new Date() } ]
};

function HereApiProvider(props) {
	const [ state, setState ] = useState(templateState);

	const triggerState = () => setState({ ...templateState, routeId: 29990 });

	return <HereApiContext.Provider value={{ state, triggerState }} {...props} />;
}

function useHERE() {
	const context = useContext(HereApiContext);
	if (context === undefined) {
		// throw new Error(`useHERE must be used within a HereApiProvider`);

		console.log(`useHERE must be used within a HereApiProvider`);
	}
	return context;
}

export { HereApiProvider, useHERE };
